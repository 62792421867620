<template>
  <div class="container">
    <div class="main">
      <top-timer></top-timer>
      <div class="chat_wrap">
        <div class="chats" id="chats">
          <!-- 别人的消息 -->
          <!-- <div class="msgitem leftmsg">
            <div class="msgbody">
              <div class="head_item"><img class="headimage" src="http://www.bl39653.xyz//userhead/16883933689603.jpeg"></div>
              <div class="usermsg_item">
                <div class="username">五福</div>
                <div class="usermsg otheruser">4严3/100</div>
              </div>
            </div>
          </div> -->
          <!-- 机器 -->
      
          <div class="msgitem leftmsg" v-for="item in list" :key="item.ID">
            <div class="msgbody">
              <div class="head_item">
                <!-- <img class="headimage" src="../../assets/icvip.png"> -->
                <img v-if="item.headpicnum==99" class="headimage" src="../../assets/kefu.jpg" /> 
                  <img v-else-if="item.headpicnum==0" class="headimage" src="../../assets/icvip.jpg" />
                 
                  <section v-else class="touxiangimg headimage" :class="'tx-'+item.headpicnum"></section>
              </div>
              <div class="usermsg_item">
                
                <div class="usermsg jqrmsg" v-if="item.optype == 0">
                    <div>
                      <div class="username jqrname">
                       
                        {{ item.account | hideaccount }} <span class="huizi">{{ item.optime | substrSome1 }}</span>
                      </div>
                      第{{ item.expect }}期
                    </div>

                    <section>
                      <span class="info"> {{ item.info }}</span>
                      <span class="amount">¥{{ item.amount }}</span>
                    </section>
                  </div>
                  <div class="usermsg jqrmsg bgred" v-if="item.optype == 2">
                    第{{ item.expect }}期  已封盘  
                  </div>
                  <div class="usermsg jqrmsg bggreen" v-if="item.optype == 1">
                    第{{ item.expect }}期  已开盘  
                  </div>
              </div>
            </div>
          </div>
      
          <!-- 机器 -->
          <!-- <div class="msgitem leftmsg">
            <div class="msgbody">
              <div class="head_item"><img class="headimage" src="/common/images/jqr.png"></div>
              <div class="usermsg_item">
                <div class="username jqrname">机器人</div>
                <div class="usermsg jqrmsg">
                  <span> -----------</span>
                  <span>51014693期核对列表:(13220)</span>

                  <span> (1号收割机)"3/100"</span>
                  <span> (得就好)"12/500"</span>
                  <span> (凤凰谷)"12/60"</span>
                  <span> -----------</span>
                  <span> 以核对列表为准</span>
                  <span> 不在列表都无效</span>
                </div>
              </div>
            </div>
          </div> -->
          <!-- 自己 -->
          <!-- <div class="msgitem rightmsg">
            <div class="msgbody">
              <div class="usermsg_item usermsg_item_right">
                <div class="username">屠夫佬</div>
                <div class="usermsg mymsg" >1223234324</div>
              </div>
              <div class="head_item">
                <img class="headimage" src="http://wx.qlogo.cn/mmhead/ver_1/icQGJwIyuicoloEBib2icWbZCpiaU1hxwynxDeoojre3UOB7UVAPAwZ3CibdrsbZticrAEmWA8yCIEkexchLBhAdiaZCGk20OK10GF5XLy5Au4qYnrc/0"></div>
            </div>
          </div> -->
          <!-- --------------- -->
        </div>
         <!-- <div class="allkeyword">
            <div class="keybody">
            <img src="../../assets/jianpan.png" class="jianpan" alt="">
                   <textarea class="inputmsg"  ></textarea>
                   <button class="sendbtn">发送</button>
            </div>

         </div> -->
      </div>

    </div>
    <div class="other">
 
      <div class="right-com">
        <div class="rc-tab">
          <span  class="selected">统计</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="ft-dsn-toji">

          <div class="thd th">
            <span>号码</span>
            <span>1 番</span>
            <span>2 番</span>
            <span>3 番</span>
            <span>4 番</span>
          </div>
          <div class="thd">
            <span>出 球 率</span>
            <span>{{ chuqiusum.fan1 }}</span>
            <span>{{ chuqiusum.fan2 }}</span>
            <span>{{ chuqiusum.fan3 }}</span>
            <span>{{ chuqiusum.fan4 }}</span>
          </div>
          <div class="thd">
            <span>无出期数</span>
            <span>{{ wuqishusum.fan1 }}</span>
            <span>{{ wuqishusum.fan2 }}</span>
            <span>{{ wuqishusum.fan3 }}</span>
            <span>{{ wuqishusum.fan4 }}</span>
          </div>
        </div>
        <div class="roadmap">
          <table class="ltbinfo">
            <tbody>
              <tr>
                <th>摊路</th>
              </tr>
            </tbody>
          </table>
          <table colspan="0" cellspan="0" class="lttab" style="display: table;">
            <tbody>
              <tr v-for="(item,index) in lutu1.arr" :key="index+10">
                <td v-for="(ele,i) in item" :class="'color'+ele" :key="ele+i">
                  <span  >{{ele}}</span>

                </td>

              </tr>
            </tbody>
          </table>
          <!-- <table class="ltbinfo">
            <tbody>
              <tr>
                <th>大小</th>
              </tr>
            </tbody>
          </table>
          <table colspan="0" cellspan="0" class="lttab" style="display: table;">
            <tbody>
              <tr v-for="(item,index) in lutu2.arr" :key="index+20">
                <td v-for="(ele,i) in item" :key="ele+i">
                  <span :class="fontColor[ele]">{{ele}}</span>

                </td>

              </tr>
            </tbody>
          </table> -->
          <table class="ltbinfo">
            <tbody>
              <tr>
                <th>单双</th>
              </tr>
            </tbody>
          </table>
          <table colspan="0" cellspan="0" class="lttab" style="display: table;">
            <tbody>
              <tr v-for="(item,index) in lutu3.arr" :key="index+30">
                <td v-for="(ele,i) in item" :key="ele+i">
                  <span :class="fontColor[ele]">{{ele}}</span>

                </td>

              </tr>
            </tbody>
          </table>
     

        </div>

      </div>

    </div>
  </div>
</template>

<script>
import mixins from "../mixins/mainMixins";
import topTimer from "../mainHeader.vue";
import { mapState } from "vuex";
export default {
  name: "chat",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      listnum:0,
      list:[],
      isFirst:true,
      kjlist: [],
      chuqiusum: {
        fan1: 0,
        fan2: 0,
        fan3: 0,
        fan4: 0,
      },
      wuqishusum: {
        fan1: 0,
        fan2: 0,
        fan3: 0,
        fan4: 0,
      },
      // 球号
      lutu1: {
        arr: [[], [], [], [], []],
      },
      // 大小
      lutu2: {
        arr: [[], [], [], [], []],
      },
      // 单双
      lutu3: {
        arr: [[], [], [], [], []],
      },
      ltArr: [],
    };
  },
 
  components: {topTimer},
  created() {},
  computed: {
    ...mapState([
   
      "currentGame",
      "userInfo",
    ,
    ])
  },
  watch: {},
  mounted() {
   window.chatTime=setInterval(() => {
    this.getInfo()
   }, 5000);
  this.getKJHis()
  },
  beforeDestroy(){
    window.clearInterval(window.chatTime)
  },
 created(){
  this.getInfo()
 },
  methods: {
    getInfo(){
  
      let obj={
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        roomid:this.currentGame.Cato,
        lastid:0
      }
    this.$http.post('chat/getchatlist',obj).then(res=>{
      if(res.Status){
       if(res.Msg){
        let resArr=JSON.parse(res.Msg)
        resArr.reverse()
        this.list=resArr;
        console.log(this.list)
        if(this.listnum!=this.list.length){
          this.$nextTick(()=>{
      var element = document.getElementById("chats");
 
 
    element.scrollTop = element.scrollHeight;
    })
    this.listnum=this.list.length
        }
 
     
//    this.isFirst=false
  
     
    
       }
      }else{
        this.$message.error(res.Msg);
      }
    })


    },
    getKJHis() {
      (this.lutu1 = {
        arr: [[], [], [], [], []],
      }),
        // 大小
        (this.lutu2 = {
          arr: [[], [], [], [], []],
        }),
        // 单双
        (this.lutu3 = {
          arr: [[], [], [], [], []],
        });
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        roomeng: this.currentGame.roomeng,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
        pagenum: 1,
        pagecount: 50,
      };
      this.$http.post("getlotteryhis", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          if (resObj.data) {
            let arr1 = [[]],
              arr2 = [[]],
              arr3 = [[]];
            let list = resObj.data;
            list.reverse()
            list.forEach((element) => {
              if(element.Opencode.includes("+")){
                let jiaArr=element.Opencode.split('+');
              element.codeArr=jiaArr[0].split(",")
              element.fan=jiaArr[1]
              }else{
                element.codeArr = element.Opencode.split(",");
              }
             let obj=this.fantangameopencode(element.codeArr)
              let sum = obj.sum;
            
              let yu='';
              if(element.Opencode.includes("+")){
                yu=element.fan
              }else{
                yu = Number(sum) % 4;
              yu = yu == 0 ? 4 : yu;
              }
           
              let dx = obj.dx;
              let ds = Number(element.fan)%2==0?'双':'单'
              // 球号
              this.initlutuData(arr1, yu);
              // 大小
              this.initlutuData(arr2, dx);
              //  单双
              this.initlutuData(arr3, ds);
            });
            this.buquanArr1(arr1, this.lutu1.arr);
            this.buquanArr1(arr2, this.lutu2.arr);
            this.buquanArr1(arr3, this.lutu3.arr);
            // console.log(this.lutu1.arr);
          } else {
            (this.lutu1 = {
              arr: [[], [], [], [], []],
            }),
              // 大小
              (this.lutu2 = {
                arr: [[], [], [], [], []],
              }),
              // 单双
              (this.lutu3 = {
                arr: [[], [], [], [], []],
              });
          }
        } else {
          (this.lutu1 = {
            arr: [[], [], [], [], []],
          }),
            // 大小
            (this.lutu2 = {
              arr: [[], [], [], [], []],
            }),
            // 单双
            (this.lutu3 = {
              arr: [[], [], [], [], []],
            });
        }
      });
    },
    getHisList() {
      this.chuqiusum = {
        fan1: 0,
        fan2: 0,
        fan3: 0,
        fan4: 0,
      };
      this.wuqishusum = {
        fan1: 0,
        fan2: 0,
        fan3: 0,
        fan4: 0,
      };
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
        roomeng: this.currentGame.roomeng,
        pagenum: 1,
        pagecount: 1000,
      };
      this.$http
        .post("getlotteryhis", obj)
        .then((res) => {
          if (res.Status) {
            let resObj = JSON.parse(res.Msg);
            this.kjlist = resObj.data;
            this.kjlist.reverse();
            this.kjlist.forEach((item) => {
              if(item.Opencode.includes("+")){
                let jiaArr=item.Opencode.split('+');
              item.codeArr=jiaArr[0].split(",")
              item.fan=jiaArr[1]
              }else{
                item.codeArr = item.Opencode.split(",");
              }
              let obj=this.fantangameopencode( item.codeArr)
              let sum = obj.sum;
            
              let yu='',fan=''
              if(item.Opencode.includes("+")){
               fan=item.fan
              }else{
                yu = Number(sum) % 4;
               fan = yu == 0 ? 4 : yu;
              }
          
              for (const key in this.chuqiusum) {
                if (key[3] == fan) {
                  this.chuqiusum[key]++;
                  this.wuqishusum[key] = 0;
                }
                if (key[3] != fan) {
                  this.wuqishusum[key]++;
                }
              }
            });
          } else {
            this.$message.error(res.Msg);
          }
        })
        .catch((err) => {});
    },
  },
};
</script>
<style scoped>
.jqrmsg.bgred{
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  background: red!important;
}
.jqrmsg.bgred:before{
  content: '';
    position: absolute;
    width: 0.16rem;
    height: 0.16rem;
   
    background: red!important;
    background-size: 0.16rem 0.02667rem, 0.02667rem 0.16rem, 0.16rem 0.16rem;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: -0.10667rem;
    top: 0.34667rem;
}
.jqrmsg.bggreen{
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
font-size: 13px;
  background: green!important;
}
.jqrmsg.bggreen:before{
  content: '';
    position: absolute;
    width: 0.16rem;
    height: 0.16rem;
   
    background: green!important;
    background-size: 0.16rem 0.02667rem, 0.02667rem 0.16rem, 0.16rem 0.16rem;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: -0.10667rem;
    top: 0.34667rem;
}
.amount {
    color: red;
    font-weight: bold;
    font-size: 13px;
}
.info {
    font-weight: bold;
    font-size: 13px;
    color: green;
}
.usermsg > section {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
   
}
.usermsg > div {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.huizi{
  font-size: 10px;
  font-weight: normal;
  color: #333;
}
.sendbtn {
    border: 1px solid #1f8b1b;
    width: 3rem;
    height: 1.8rem;
    border-radius: 3px;
    text-align: center;
    line-height: 26px;
    font-weight: bold;
    color: #ffffff;
    background: #1aac19;
    outline: none;
}
 
.inputmsg {
    width: 60%;
    background: #fcfcfc;
    border: 1px solid #dcdcde;
    height: 1.8rem;
    line-height: 1.8rem;
    border-radius: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    outline: none;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.2rem;
    resize: none
}
.jianpan{
  width: 29px;
  height: 29px;
}
.keybody {
    height: 2.5rem;
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-content: space-between;
}
.allkeyword {
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background-color: #f5f5f7;
    border: 1px solid #dcdcde;
}
.mymsg:before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    background: linear-gradient(to top, #84b559, #84b559) no-repeat, linear-gradient(to right, #84b559, #84b559) no-repeat, linear-gradient(135deg, #a1e85a, #a1e85a 6px, hsla(0,0%,100%,0) 6px) no-repeat;
    background-size: 6px 1px, 1px 6px, 6px 6px;
    transform: rotate(-225deg);
    right: -4px;
    top: 13px;
}
.mymsg {
  background-color: #a1e85a !important;
  border: 1px solid #84b559;
}
.usermsg_item_right {
  text-align: right;
  padding-left: 0rem;
  padding-right: 0.5rem;
}
.rightmsg {
  justify-content: flex-end;
}
 
.jqrmsg:before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background: linear-gradient(to top, #d1d1d1, #d1d1d1) no-repeat,
    linear-gradient(to right, #d1d1d1, #d1d1d1) no-repeat,
    linear-gradient(135deg, #eff0fe, #eff0fe 6px, hsla(0, 0%, 100%, 0) 6px)
      no-repeat;
  background-size: 6px 1px, 1px 6px, 6px 6px;
  transform: rotate(-45deg);
  left: -4px;
  top: 13px;
  border-left: 0.02667rem solid #f89007;
    border-top: 0.02667rem solid #f89007;
}
.usermsg > span {
  display: block;
}
.jqrmsg {
  background-color: #eff0fe !important;
}
.jqrname {
  padding: 1px;
  font-size: 16px;
  font-weight: bold!important;
  color: #0d6eec !important;
}
.otheruser:before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background: linear-gradient(to top, #d1d1d1, #d1d1d1) no-repeat,
    linear-gradient(to right, #d1d1d1, #d1d1d1) no-repeat,
    linear-gradient(135deg, #fff, #fff 6px, hsla(0, 0%, 100%, 0) 6px) no-repeat;
  background-size: 6px 1px, 1px 6px, 6px 6px;
  transform: rotate(-45deg);
  left: -4px;
  top: 13px;
}
.usermsg {
  width:60%;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  line-height: 18px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
  border: 2px solid #f89007;
  border-radius: 7px;
  position: relative;
  display: flex;
}
.username {
  display: inline-block;
  height: 15px;
  line-height: 15px;
  color: #6f6f6f;
  font-weight: 100;
  white-space: nowrap;
  font-size: 1rem;
}
.usermsg_item {
  width: calc(100% - 40px);
  padding-left: 0.5rem;
}
.headimage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.head_item {
  width: 40px;
}
.msgbody {
  width: 90%;
  display: flex;
}
.leftmsg {
  justify-content: flex-start;
}

.msgitem {
  display: flex;
  margin-bottom: 5px;
  width: 100%;
}
.chats {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* bottom: 2.5rem; */
  overflow-y: scroll;
 
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
 
}
.chat_wrap {
  position: relative;
  /* width: 800px; */
  height: calc(100vh - 265px);
  background: url('../../assets/tele.jpg');
  background-size: cover;
  /* border: 1px solid red; */
}
/* --------------------------------------------- */
.r{
  font-weight: bold;
}
.roadmap table th{
  padding: 2px;
}
.roadmap table td {
  height: 17px;
}
.ft-dsn .w-left .n b:last-child,
.ft-dsn .w-right .n b:last-child {
  margin-right: 0;
}
.ft-dsn .ul-row1.ul3 li {
  width: 50%;
  height: 100%;
}
.ft-dsn .f-abso .f-svgbox .s-itemds li {
  background: #ecf4fe;
}
.ft-dsn .f-abso .f-svgbox .s-itemds {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 50%;
  height: 50%;
  border: 1px solid #b9c2cb;
}

.ft-dsn .f-abso .f-svgbox .s-item .n {
  color: #1a9f49;
}
.ft-dsn .f-abso .f-svgbox .s-item > div {
  width: 100%;
  text-align: center;
}
.ft-dsn .f-abso .f-svgbox .s-item {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  height: 25%;
}

.ft-dsn .f-abso .f-svgbox .s-item.d {
  right: 11px;
}

.ft-dsn .f-abso .f-svgbox .s-item.c,
.ft-dsn .f-abso .f-svgbox .s-item.d {
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20%;
}
.ft-dsn .f-abso .f-svgbox .s-item.c {
  left: 11px;
}

.ft-dsn .f-abso .f-svgbox .s-item.b {
  bottom: 4px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 50px;
}

.ft-dsn .f-abso .f-svgbox .s-item.a {
  z-index: 1;
  top: 4px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 50px;
}
.ft-dsn .f-abso .f-svgbox .f-svg {
  height: 226px;
}
.ft-dsn .f-abso .f-svgbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 226px;
  padding: 0;
  background: #d7d7d7;
  border: 1px solid #b9c2cb;
  border-radius: 15px;
  overflow: hidden;
}

.ft-dsn .row3 {
  width: 60%;
}
.ft-dsn .f-abso {
  position: relative;
}
.ft-dsn .ul-row1.ul2 li {
  width: 100%;
}
.ft-dsn .ul-row1.ulh2 li {
  height: 76px;
}
.ft-dsn .b-svg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ft-dsn .w-left .t,
.ft-dsn .w-right .t {
  color: #ff4081;
  font-size: 17px;
  font-weight: 700;
  line-height: 18px;
}
.ft-dsn .ul-row1.ulh li {
  height: 90px;
}
.ft-dsn .w-right {
  background: #ecf4fe;
}
/* -------统计 */
.ft-dsn-toji h3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 24px;
}
.ft-dsn-toji h3 span.s {
  font-weight: 700;
}
.ft-dsn-toji h3 span:first-child {
  border-left: none;
}
.ft-dsn-toji h3 span {
  width: 100%;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
}
.ft-dsn-toji .thd.th {
  background: #eee;
}
.ft-dsn-toji .thd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ft-dsn-toji ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ft-dsn-toji ul li {
  width: 2.5%;
  min-height: 50px;
}
.ft-dsn-toji .thd span:first-child {
  width: 20%;
}
.ft-dsn-toji .thd span {
  width: 20%;
  text-align: center;
  line-height: 18.5px;
}
.ft-dsn-toji ul li span {
  display: block;
  line-height: 20px;
  text-align: center;
}
.ft-dsn-toji ul li span.blue {
  color: #00f;
}
.ft-dsn-toji ul li span.red {
  color: red;
}
/* -------三个皮肤的  */
.skin-blue .ft-dsn-toji h3 span {
  border-left: 1px solid #b9c2cb;
}
.skin-red .ft-dsn-toji h3 span {
  border-left: 1px solid #e6b3be;
}
.skin-brown .ft-dsn-toji h3 span {
  border-left: 1px solid #d8c295;
}
.skin-brown .ft-dsn-toji h3 span:nth-child(2) {
  border-left: 1px solid #d8c295;
}
.skin-brown .ft-dsn-toji .thd span,
.skin-brown .ft-dsn-toji ul li {
  border-left: 1px solid #d8c295;
  border-top: 1px solid #d8c295;
}
.skin-red .ft-dsn-toji .thd span,
.skin-red .ft-dsn-toji ul li {
  border-left: 1px solid #e6b3be;
  border-top: 1px solid #e6b3be;
}
.skin-blue .ft-dsn-toji .thd span,
.skin-blue .ft-dsn-toji ul li {
  border-left: 1px solid #b9c2cb;
  border-top: 1px solid #b9c2cb;
}
.skin-brown .ft-dsn-toji .thd span:first-child,
.skin-brown .ft-dsn-toji ul li:first-child {
  border-left: none;
}
.skin-red .ft-dsn-toji .thd span:first-child,
.skin-red .ft-dsn-toji ul li:first-child {
  border-left: none;
}
.skin-blue .ft-dsn-toji .thd span:first-child,
.skin-blue .ft-dsn-toji ul li:first-child {
  border-left: none;
}
.skin-brown .ft-dsn-toji h3 span.s {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f4ecd9),
    color-stop(50%, #f4ecd9),
    color-stop(0, #e2dac8),
    to(#e2dac8)
  );
  background: linear-gradient(180deg, #f4ecd9, #f4ecd9 50%, #e2dac8 0, #e2dac8);
}
.skin-red .ft-dsn-toji h3 span.s {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(25%, #f5e2e2),
    color-stop(49%, #f5e2e2),
    color-stop(50%, #f1d6d4),
    to(#f1d6d4)
  );
  background: linear-gradient(
    180deg,
    #f5e2e2 25%,
    #f5e2e2 49%,
    #f1d6d4 50%,
    #f1d6d4
  );
}
.skin-blue .ft-dsn-toji h3 span.s {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#dee9f3),
    color-stop(50%, #dee9f3),
    color-stop(51%, #cfd9e3),
    to(#cfd9e3)
  );
  background: linear-gradient(
    180deg,
    #dee9f3 0,
    #dee9f3 50%,
    #cfd9e3 51%,
    #cfd9e3
  );
}
.skin-brown .ft-dsn-toji h3 {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    color-stop(50%, #fff),
    color-stop(51%, #fdeee1),
    to(#fdeee1)
  );
  background: linear-gradient(180deg, #fff, #fff 50%, #fdeee1 51%, #fdeee1);
}
.skin-red .ft-dsn-toji h3 {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    color-stop(50%, #fff),
    color-stop(51%, #ffefee),
    to(#ffefee)
  );
  background: linear-gradient(180deg, #fff, #fff 50%, #ffefee 51%, #ffefee);
}
.skin-blue .ft-dsn-toji h3 {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #fff),
    color-stop(50%, #fff),
    color-stop(51%, #fef4ea),
    to(#fef4ea)
  );
  background: linear-gradient(180deg, #fff, #fff 50%, #fef4ea 51%, #fef4ea);
}
.skin-brown .ft-dsn-toji {
  border: 1px solid #d8c295;
}
.skin-red .ft-dsn-toji {
  border: 1px solid #e6b3be;
}
.skin-blue .ft-dsn-toji {
  border: 1px solid #b9c2cb;
}
.skin-blue .ft-dsn .items.selected,
.skin-blue .ft-dsn .items.selected:hover {
  background: #ffc214 !important;
}
.special-effect {
  fill: #87c8ad;
  background: #87c8ad;
}
.skin-blue .ft-dsn .items.svg-hover:hover {
  background: #87c8ad !important;
}
path.selected {
  fill: #ffc214 !important;
}
.skin-red .ft-dsn .items.selected,
.skin-red .ft-dsn .items.selected:hover {
  background: #ffc214 !important;
}

.skin-red .ft-dsn .items.svg-hover:hover {
  background: #87c8ad !important;
}
.skin-brown .ft-dsn .items.selected,
.skin-brown .ft-dsn .items.selected:hover {
  background: #ffc214 !important;
}

.skin-brown .ft-dsn .items.svg-hover:hover {
  background: #87c8ad !important;
}
.skin-brown .ft-dsn .items:hover {
  background: #e2dac8 !important;
}
.skin-blue .ft-dsn .items:hover {
  background: #c3d9f1 !important;
}

.skin-red .ft-dsn .items:hover {
  background: #f6d3e4 !important;
}

.skin-blue .ft-dsn .items.selected,
.skin-blue .ft-dsn .items.selected:hover {
  background: #ffc214 !important;
}
.skin-brown .ft-dsn .items.selected,
.skin-brown .ft-dsn .items.selected:hover {
  background: #ffc214 !important;
}
.skin-red .ft-dsn .items.selected,
.skin-red .ft-dsn .items.selected:hover {
  background: #ffc214 !important;
}
/* ------------------------------------------------------------ */
.ft-dsn .w-left .p input,
.ft-dsn .w-right .p input {
  width: 70%;
  border-radius: 4px;
  border: 1px solid #bdc2c7;
  height: 15px;
  background: #fff;
  outline: none;
  text-align: center;
}
.ft-dsn .w-left .r,
.ft-dsn .w-right .r {
  color: red;
  font-family: Arial, Microsoft Yahei, Helvetica, sans-serif;
}
.ft-dsn .w-left .n b,
.ft-dsn .w-right .n b {
  margin-right: 12px;
  font-family: Arial, Microsoft Yahei, Helvetica, sans-serif;
}
.ft-dsn .w-left .n b,
.ft-dsn .w-right .n b {
  margin-right: 8px;
}
.ft-dsn .select-ul li div,
.ft-dsn .ul-row1 li div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}
.ft-dsn .w-left .c,
.ft-dsn .w-left .n,
.ft-dsn .w-left .r,
.ft-dsn .w-right .c,
.ft-dsn .w-right .n,
.ft-dsn .w-right .r {
  height: 17px;
  line-height: 17px;
}
.ft-dsn .w-left .n,
.ft-dsn .w-right .n {
  font-size: 17px;
}
.ft-dsn .items {
  cursor: pointer;
}
.ft-dsn .select-ul,
.ft-dsn .select-ul li,
.ft-dsn .ul-row1 li,
.ft-dsn .ul-row1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.ft-dsn .select-ul li,
.ft-dsn .ul-row1 li {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 20%;
  padding: 10px 0;
}
.ft-dsn .select-ul li {
  height: 95.75px;
}
.ft-dsn .select-ul li,
.ft-dsn .td,
.ft-dsn .ul-row1 li {
  border: 1px solid #bdc2c7;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.ft-dsn .row3 {
  width: 60%;
}
.ft-dsn .row1 {
  width: 20%;
}
.ft-dsn .fth .td {
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-weight: 700;
  background: #e8e8e8;
}
.ft-dsn .fth {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ft-dsn .w-left:before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  content: attr(data-attr);
  font-size: 100px;
  color: #e0e0e0;
}
.ft-dsn .w-left {
  position: relative;
}
.ft-dsn .w-left,
.ft-dsn .w-right {
  width: calc((100% - 10px) / 2);
  color: #787878;
}
/* 翻滩 */
.ft-dsn {
  margin-top: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid #bdc2c7;
}
</style>